var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "navbar" },
    [
      _c("hamburger", {
        staticClass: "hamburger-container",
        attrs: { id: "hamburger-container", "is-active": _vm.sidebar.opened },
        on: { toggleClick: _vm.toggleSideBar },
      }),
      _vm._v(" "),
      !_vm.topNav
        ? _c("breadcrumb", {
            staticClass: "breadcrumb-container",
            attrs: { id: "breadcrumb-container" },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.topNav
        ? _c("top-nav", {
            staticClass: "topmenu-container",
            attrs: { id: "topmenu-container" },
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "right-menu" },
        [
          _vm.device !== "mobile"
            ? [
                _c("search", {
                  staticClass: "right-menu-item",
                  attrs: { id: "header-search" },
                }),
                _vm._v(" "),
                _c("screenfull", {
                  staticClass: "right-menu-item hover-effect",
                  attrs: { id: "screenfull" },
                }),
                _vm._v(" "),
                _c("span", {
                  staticClass:
                    "iconfont iconios-notifications-outline right-menu-item",
                  staticStyle: { "font-size": "22px", opacity: "0.5" },
                }),
              ]
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "platformLabel" }, [_vm._v("商户")]),
          _vm._v(" "),
          _c(
            "el-dropdown",
            {
              staticClass: "avatar-container right-menu-item hover-effect",
              attrs: { trigger: "click" },
            },
            [
              _c("div", { staticClass: "avatar-wrapper" }, [
                _vm._v(_vm._s(_vm.JavaMerInfo.realName)),
                _c("i", { staticClass: "el-icon-arrow-down el-icon--right" }),
              ]),
              _vm._v(" "),
              _c(
                "el-dropdown-menu",
                { attrs: { slot: "dropdown" }, slot: "dropdown" },
                [
                  _c(
                    "router-link",
                    { attrs: { to: "/" } },
                    [_c("el-dropdown-item", [_vm._v("首页")])],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-dropdown-item",
                    {
                      nativeOn: {
                        click: function ($event) {
                          _vm.setting = true
                        },
                      },
                    },
                    [_c("span", [_vm._v("布局设置")])]
                  ),
                  _vm._v(" "),
                  _c(
                    "router-link",
                    {
                      directives: [
                        {
                          name: "hasPermi",
                          rawName: "v-hasPermi",
                          value: ["merchant:login:admin:update"],
                          expression: "['merchant:login:admin:update']",
                        },
                      ],
                      attrs: { to: { path: "/maintain/user/users" } },
                    },
                    [_c("el-dropdown-item", [_vm._v("个人中心")])],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "router-link",
                    {
                      directives: [
                        {
                          name: "hasPermi",
                          rawName: "v-hasPermi",
                          value: ["merchant:login:admin:update:password"],
                          expression:
                            "['merchant:login:admin:update:password']",
                        },
                      ],
                      attrs: { to: { path: "/maintain/user/password" } },
                    },
                    [_c("el-dropdown-item", [_vm._v("修改密码")])],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-dropdown-item",
                    {
                      directives: [
                        {
                          name: "hasPermi",
                          rawName: "v-hasPermi",
                          value: ["merchant:logout"],
                          expression: "['merchant:logout']",
                        },
                      ],
                      nativeOn: {
                        click: function ($event) {
                          return _vm.logout($event)
                        },
                      },
                    },
                    [_c("span", [_vm._v("退出")])]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }